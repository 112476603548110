<!-- 文件中文名: /安置网络图(图形) -->
<template>
  <div class="worksheet-structure app-container">
    <div class="page-container account">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <!--经营权编号-->
          <el-form-item :label="$t('miMember.rightNo')+':'">
            <el-input @input="value=>listQuery.rightNo=value.toUpperCase()" v-model="listQuery.rightNo" size="mini"/>
          </el-form-item>
          <!--查询层数-->
          <el-form-item v-if="listQuery.treeOrView != 'View'" :label="$t('miMember.Layer')+':'">
            <el-input-number v-model="listQuery.Layer" size="mini"/>
          </el-form-item>
          <el-form-item>
            <!--搜索-->
            <el-button :loading="loading" icon="el-icon-search" plain type="primary" @click="handleFilter">
              {{ $t('operation.button.search') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="listQuery.treeOrView==='View'" style="width: 100%;">
        <organization-chart
            v-show="Object.keys(viewData).length"
            v-loading="loading"
            :datasource="viewData"
            :pan="true"
            :zoom="true"
            :zoomin-limit="2"
            :zoomout-limit="0.5"
        >
          <template slot-scope="{ nodeData }">
            <div class="orgchartnnode" v-show="nodeData.rightNo" :class="colorOne[nodeData.levelType ? (nodeData.levelType).substr(0,1) : '']">
              <p style="margin: 0;color: blue;text-decoration: underline;cursor: pointer"
                 @click="getClickData(nodeData.rightNo)">
                {{ nodeData.rightNo }}
              </p>
              <p style="margin: 0;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;margin-left: 30%">
                {{ nodeData.name }}
              </p>
              <div style="margin: 3px 20px">
                <div  v-for="(i,index) in nodeData.subordinate" :key="i.levelType" >
                  <div :class="color[index]" style="margin: 0;padding: 5px">{{$lt(initDict.mimemberrightleveltype,i.levelType)}} - {{$t('rpt.memCount')+':'}}{{ i.personNum }}</div>
                </div>
              </div>
              <p style="margin: 5px">{{$t('busi.poMemberOrder.total.pv')+':'}}{{ nodeData.netTotalFv }}</p>
              <p style="margin: 5px">{{ nodeData.registerDate }}</p>
            </div>
          </template>
        </organization-chart>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex"
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import {getPositionViews1} from "@/api/rus/api";

export default {
  name: 'WorksheetStructure',
  directives: {},
  components: {OrganizationChart},
  data() {
    return {
      colorOne:['levelType0','levelType1','levelType2','levelType3','levelType4'],
      color:['levelType4','levelType3','levelType2','levelType1', 'levelType0'],
      loading: false,
      viewData: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      listQuery: {
        direction: '1',
        rightNo: '',
        treeOrView: 'View',
        Layer: 2,
      },
      initDict: {
        treeorview: 'treeorview',
        yesno: 'yesno',
        mimemberrightleveltype: 'ru.member.level',
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode'
    ]),
    treeorview() {
      return this.$ll(this.initDict.treeorview)
    },
    yesno() {
      return this.$ll(this.initDict.yesno)
    },
    mimemberrightleveltype() {
      return this.$ll(this.initDict.mimemberrightleveltype)
    },
  },
  created() {
    this.handleFilter()
  },
  mounted() {
    this.listQuery.rightNo = this.userCode
  },
  methods: {
    handleFilter() {
      this.listQuery.rightNo = this.listQuery.rightNo.toUpperCase()
      if (!this.listQuery.rightNo) {
        return false
      }
      this.loading = true
      getPositionViews1({...this.listQuery}).then(res => {
        this.loading = false
        this.viewData = res.data.data
      }).catch(err => {
        this.loading = false
        this.viewData = {}
        console.warn(err)
      })
    },
    handleFilter2() {
      this.listQuery.rightNo = this.listQuery.rightNo.toUpperCase()
      this.viewData.linkNo = this.viewData.linkNo.toUpperCase()
      if (!this.listQuery.rightNo) {
        return false
      }
      if (!this.viewData.linkNo) {
        return false
      }
      this.loading = true
      getPositionViews1({...this.listQuery, rightNo: this.viewData.linkNo, direction: 1}).then(res => {
        this.loading = false
        this.viewData = res.data.data
      }).catch(err => {
        this.loading = false
        console.warn(err)
        this.viewData = {}
      })
    },
    copyData(rightNo) {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly')
      input.setAttribute('value', rightNo)
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        console.log('复制成功')
      }
      document.body.removeChild(input);
    },
    // eslint-disable-next-line no-unused-vars
    getClickData(rightNo) {
      this.loading = true
      getPositionViews1({...this.listQuery, rightNo: rightNo.toUpperCase()}).then(res => {
        this.loading = false
        this.viewData = res.data.data
      }).catch(err => {
        this.loading = false
        this.viewData = {}
        console.warn(err)
      })
    },
    setAllExpand(state) {
      const nodes = this.$refs.tree.store.nodesMap
      for (const i in nodes) {
        nodes[i].expanded = state
        this.$set(nodes[i], 'expanded', state)
      }
    },
  }
}

</script>
<style scoped lang="scss">
  .el-popover.pop-div-tree {
    text-align: center;
    background-image: linear-gradient(0deg,#fbf5e4,#eeb9b9);
    padding: 0;
    line-height: normal;
    box-shadow: 0 0 11px 0 rgba(174, 187, 211, 0.24);
    border:solid 1px #9eff00;
  }

  ::v-deep .orgchart{
    width: 100%;
    background-size:0 0;
    .node{
      width: 200px;
      margin-right: 10px;
    }
  }
  .orgchart-container {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .Layercss {
    margin-left: 10px;
  }

  .pred {
    color: red;
    display: inline;
  }

  .orgchartnnode p {
    padding: 0;
    margin: 0;
  }

  ::v-deep .mytree {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node {
      position: relative;
      padding-left: 16px;
    }
    .el-tree-node__content {
      height: 70px;
    }

    .el-tree-node__expand-icon.is-leaf {
      display: none;
    }

    .el-tree-node__children {
      padding-left: 16px;
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 2px solid #9b9b9b;
      bottom: 0;
      height: 100%;
      top: 0;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 2px solid #9b9b9b;
      height: 20px;
      top: 37px;
      width: 24px;
    }

    .el-tree>.el-tree-node {
      display: inline-block;
      min-width: 100%;
    }
  }

  .div_in_table {
    th {
      width: 100px;
    }
  }

</style>
<style scoped>
  .orgchartnnode {
    width: 200px;
    min-height: 100px;
    line-height: 20px;
    border-radius: 10px
  }
.levelType0{
  background-image: linear-gradient(#ffffff, #f7f7f7);
}
.levelType1{
  background-image: linear-gradient(#cdcdcd, #eaedf1);
}
.levelType2{
  background-image: linear-gradient(#50d2e7,#4dd5ed);
}
.levelType3{
  background-image: linear-gradient(#f1dc99,#e1c063);
}
.levelType4{
  background-image: linear-gradient(#f4748f,#ffa8af);
}

/deep/ .orgchart .node{
  width: 210px !important;
}
  /deep/ .orgchart{
    cursor: default; transform: matrix(0.8, 0, 0, 0.8, -86, -88);
}
</style>
